import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import { manuallyAddTransaction, transactionManuallyAdded, transactionNotManuallyAdded } from './PresenceMonitor.redux';
import { ManualTransaction } from '../../models/PresenceMonitor/ManualTransaction';

// **************************************************
// ********************* MANUALLY ADD TRANSACTION SAGAS ***************

// Worker Sagas
function* manuallyAddTransactionSaga() {
  yield takeEvery(manuallyAddTransaction.type, manuallyAddTransactionRequest);
}

function* transactionManuallyAddedSaga() {
  yield takeLatest(transactionManuallyAdded.type, manuallyAddTransactionResponse);
}

function* transactionNotManuallyAddedSaga() {
  yield takeLatest(transactionNotManuallyAdded.type, manuallyAddTransactionError);
}

// Request
function* manuallyAddTransactionRequest(action: PayloadAction<ManualTransaction>) {
  try {
    const { payload: data } = action;
    yield apiService.execute({
      url: `Transactions/ManuallyAddTransaction`,
      method: ApiRequestType.POST,
      data,
    });
  } catch ({ message }) {
    yield put({ type: transactionNotManuallyAdded.type, payload: { msg: { message } } });
  }
}

// Response
function manuallyAddTransactionResponse() {
  notificationService.showSuccess('presenceMonitor.notifications.personRemoved');
}

// Error
function manuallyAddTransactionError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('presenceMonitor.notifications.removeFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Manually add transaction
    manuallyAddTransactionSaga(),
    transactionManuallyAddedSaga(),
    transactionNotManuallyAddedSaga(),
  ]);
}
