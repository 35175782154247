import React, { useMemo, useState } from 'react';
import { Select, Skeleton } from 'antd';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

// Constants
import { AppColors } from '../../../constants/Utils/Colors';
// Models
import { PresenceMonitorMetricsResponse } from '../../../models/PresenceMonitor/PresenceMonitorResponse';
import { PresenceMonitorDashboardDataType } from '../../../models/PresenceMonitor/PresenceMonitorDashboardDataType';
// Components
import { Translated } from '../../UI/Core';
import { DarkTooltip } from '../../UI/Chart/DarkTooltip';
import { WeekdayTick } from '../../UI/Chart/WeekdayTick';

// Props
interface PresenceMonitorMetricsProps {
  presenceMonitorMetrics?: PresenceMonitorMetricsResponse;
}

const personTypeOptions = [
  {
    value: PresenceMonitorDashboardDataType.All,
    label: <Translated id="presenceMonitor.dashboard.stats.allVisits" />,
  },
  {
    value: PresenceMonitorDashboardDataType.ThisWeek,
    label: <Translated id="presenceMonitor.dashboard.stats.thisWeek" />,
  },
  {
    value: PresenceMonitorDashboardDataType.LastWeek,
    label: <Translated id="presenceMonitor.dashboard.stats.lastWeek" />,
  },
];

// Component
export const PresenceMonitorMetrics = ({ presenceMonitorMetrics }: PresenceMonitorMetricsProps) => {
  const [selectedPeriod, setSelectedPeriod] = useState(PresenceMonitorDashboardDataType.All);

  // Memoized data for graph based on selection
  const filteredMetrics = useMemo(() => {
    if (selectedPeriod === PresenceMonitorDashboardDataType.All) {
      return presenceMonitorMetrics;
    }
    return presenceMonitorMetrics?.map((entry) => ({
      Name: entry.Name,
      ThisWeek: selectedPeriod === PresenceMonitorDashboardDataType.ThisWeek ? entry.ThisWeek : 0,
      LastWeek: selectedPeriod === PresenceMonitorDashboardDataType.LastWeek ? entry.LastWeek : 0,
    }));
  }, [presenceMonitorMetrics, selectedPeriod]);

  return (
    <div className="ant-col gx-visit-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-13">
      <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h6 className="gx-text-uppercase gx-mb-2 gx-mb-xl-4">
            <Translated id="presenceMonitor.dashboard.chart.title" />
          </h6>
          <Select
            value={selectedPeriod}
            options={personTypeOptions}
            onChange={(value) => setSelectedPeriod(value)}
            style={{ width: 140, marginRight: 0 }}
          />
        </div>
        {filteredMetrics ? (
          <ResponsiveContainer width="100%" height={162}>
            <AreaChart data={filteredMetrics} margin={{ top: 4, right: 8, left: 16, bottom: 0 }}>
              <CartesianGrid horizontal={false} strokeDasharray="3 3" />
              <XAxis tick={<WeekdayTick intlId="visitors.dashboard.short." />} interval={0} dataKey="Name" />
              <YAxis width={12} fontSize={12} fontWeight={600} allowDecimals={false} />
              <Tooltip content={<DarkTooltip intlId="visitors.dashboard." />} />
              <Area
                // LastYear
                dataKey="LastWeek"
                type="monotone"
                stroke={AppColors.cyan}
                fill={AppColors.cyan}
              />
              <Area
                // ThisYear
                dataKey="ThisWeek"
                type="monotone"
                stroke={AppColors.lime}
                fill={AppColors.lime}
              />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <Skeleton />
        )}
      </div>
    </div>
  );
};
