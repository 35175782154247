import React from 'react';

import { RemoveButton } from '../../components/PresenceMonitor/RemoveButton';

// Types
import { TableColumn } from '../../types/Table';

// Components
import { Translated } from '../../components/UI/Core';
import { Person } from '../../models/PresenceMonitor/Person';

// Table Columns
export const presenceMonitorTableColumns: Array<TableColumn<Person>> = [
  {
    Header: <Translated id="person.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="person.fullName" />,
    id: 'FullName',
    accessor: 'FullName',
  },
  {
    Header: <Translated id="person.email" />,
    id: 'Email',
    accessor: 'Email',
    isVisible: true,
  },
  {
    Header: <Translated id="person.phone" />,
    id: 'PhoneNumber',
    accessor: 'PhoneNumber',
    disableSortBy: true,
  },
  {
    Header: <Translated id="person.companyName" />,
    id: 'CompanyName',
    accessor: 'CompanyName',
  },
  {
    Header: <Translated id="person.type" />,
    id: 'PersonType',
    accessor: 'PersonType',
  },
  {
    Header: <Translated id="person.area" />,
    id: 'AreaName',
    accessor: 'AreaName',
  },
  {
    Header: <Translated id="person.info.options" />,
    id: 'person.info.options',
    accessor: 'Action',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }: { row: { original: Person } }) => (
      <RemoveButton size="small" authorizedUserId={row.original.Id} areaId={row.original.AreaId} />
    ),
  },
];
