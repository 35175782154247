import React from 'react';
import {FieldTimeOutlined, InfoCircleOutlined, ThunderboltFilled} from '@ant-design/icons';
import { Skeleton } from 'antd';
import { useIntl } from 'react-intl';

// Models
import { Visit } from '../../../../models/Visits/Visit';

// Components
import { LabelWithIcon } from '../../../UI/Label/LabelWithIcon';
import { TimeframeTags } from '../../../UI/Tag/TimeframeTags';

// Props
interface VisitDetailsProps {
  visit?: Visit;
}

// Component
export const VisitDetails = ({ visit = undefined }: VisitDetailsProps) => {
  // Intl
  const intl = useIntl();

  // Loading
  if (!visit) return <Skeleton />;

  // Render
  return (
    <>
      <LabelWithIcon
        icon={<FieldTimeOutlined />}
        translationId="visits.info.time"
        value={<TimeframeTags validFrom={visit.ValidFrom} validTo={visit.ValidTo} />}
      />

      <LabelWithIcon
        icon={<InfoCircleOutlined />}
        translationId="visits.info.description"
        value={visit.Description || intl.formatMessage({ id: 'visits.info.noReasonGiven' })}
      />

      <LabelWithIcon
        icon={<ThunderboltFilled />}
        translationId="visits.info.energyConsumption"
        value={visit.EnergyConsumption || '-' }
      />
    </>
  );
};
