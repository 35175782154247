/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { SignalRAction } from '../../models/SignalRAction';
import { AuthorizedUserProfilePicture } from '../../models/AuthorizedUserProfilePicture';

// State
interface AuthorizedUserState {
  error: boolean;
  updating: boolean;
}

const initialState: AuthorizedUserState = {
  error: false,
  updating: false,
};

// Name
const storeName = '@AUTHORIZED_USER';

// Redux Actions|Reducers
const authorizedUserProfilePictureSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Update files
    updateAuthorizedUserFile: (state, action: PayloadAction<AuthorizedUserProfilePicture>) => {
      state.updating = true;
      state.error = false;
    },
    authorizedUserFileUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    authorizedUserFileNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete files
    deleteAuthorizedUserFile: (state, action: PayloadAction<AuthorizedUserProfilePicture>) => {
      state.updating = true;
      state.error = false;
    },
    authorizedUserFileDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    authorizedUserFileNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Update files
  updateAuthorizedUserFile,
  authorizedUserFileUpdated,
  authorizedUserFileNotUpdated,
  // Delete files
  deleteAuthorizedUserFile,
  authorizedUserFileDeleted,
  authorizedUserFileNotDeleted,
} = authorizedUserProfilePictureSlice.actions;

// Export Reducer
export default authorizedUserProfilePictureSlice.reducer;
