// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  authorizedUserFileDeleted,
  authorizedUserFileNotDeleted,
  authorizedUserFileNotUpdated,
  authorizedUserFileUpdated,
} from './AuthorizedUserProfilePicture.redux';

// Events
const SignalREvents = {
  authorizedUserFileUpdated: `AuthorizedUserFileUpdatedIntegrationEvent`,
  authorizedUserFileDeleted: `AuthorizedUserFileDeletedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  authorizedUserFileNotUpdated: `AuthorizedUserFileNotUpdatedIntegrationException`,
  authorizedUserFileNotDeleted: `AuthorizedUserFileNotDeletedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Update file
  hubConnection.on(SignalREvents.authorizedUserFileUpdated, (msg) => {
    store.dispatch(authorizedUserFileUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.authorizedUserFileNotUpdated, (msg) => {
    store.dispatch(authorizedUserFileNotUpdated({ history, msg }));
  });

  // Delete file
  hubConnection.on(SignalREvents.authorizedUserFileDeleted, (msg) => {
    store.dispatch(authorizedUserFileDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.authorizedUserFileNotDeleted, (msg) => {
    store.dispatch(authorizedUserFileNotDeleted({ history, msg }));
  });
};
