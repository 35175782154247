import React, { useCallback, useEffect, useMemo } from 'react';

// Hooks
import { uniqueId } from 'lodash';
import { useData } from '../../App/useData';

// Models
// Components
import { Translated } from '../../../components/UI/Core';
import { DashboardIcon } from '../../../components/UI/Icon/DashboardIcon';

// Data
import { ApiEndpoints } from '../../../data/ApiEndpoints';
import { AppColors } from '../../../constants/Utils/Colors';
import { PresenceMonitorDashboardDataType } from '../../../models/PresenceMonitor/PresenceMonitorDashboardDataType';
import { PresenceMonitorStatsContent } from '../../../components/PresenceMonitor/Dashboard/PresenceMonitorStats';
import {
  PresenceMonitorMetricsResponse,
  PresenceMonitorStat,
  PresenceMonitorStatsResponse,
  Weeks,
} from '../../../models/PresenceMonitor/PresenceMonitorResponse';

const getStatColor = (period: string) => {
  switch (period) {
    case PresenceMonitorDashboardDataType.ThisWeek.toString():
      return AppColors.green;
    case PresenceMonitorDashboardDataType.LastWeek.toString():
      return AppColors.cyan;
    default:
      return undefined;
  }
};

const getStatLabel = (period: string) => {
  switch (period) {
    case PresenceMonitorDashboardDataType.ThisWeek.toString():
      return <Translated id="presenceMonitor.dashboard.stats.thisWeek" />;
    case PresenceMonitorDashboardDataType.LastWeek.toString():
      return <Translated id="presenceMonitor.dashboard.stats.lastWeek" />;
    default:
      return <span />;
  }
};

const getStatIcon = (period: string) => {
  switch (period) {
    case PresenceMonitorDashboardDataType.ThisWeek.toString():
      return <DashboardIcon className="icon icon-contacts" />;
    case PresenceMonitorDashboardDataType.LastWeek.toString():
      return <DashboardIcon className="icon icon-contacts" />;
    default:
      return <span />;
  }
};

const getStat: (item: PresenceMonitorStat) => PresenceMonitorStatsContent = (item) => {
  return {
    id: uniqueId(),
    value: item.Count,
    icon: getStatIcon(item.Period),
    label: getStatLabel(item.Period),
    color: getStatColor(item.Period),
  };
};

// Hook
export const usePresenceMonitorDashboard = () => {
  // Data
  const { data, fetch } = useData(ApiEndpoints.PresenceMonitor.GetWeeklyHistoricalData, null);

  const presenceMonitorStats: PresenceMonitorStatsResponse = useMemo(() => {
    // Function to get total count for a specific week
    const getTotalCountForWeek = (week: number) => {
      const weekData = data?.find((entry) => entry.Week === week);
      return weekData ? weekData.DayCounts.reduce((sum, count) => sum + count, 0) : 0;
    };

    // Calculate total counts for this week and last week
    const totalCountThisWeek = getTotalCountForWeek(Weeks.ThisWeek); // Week 0 is the current week
    const totalCountLastWeek = getTotalCountForWeek(Weeks.LastWeek); // Week 1 is the last week

    // Return stats for both weeks
    return [
      { Period: PresenceMonitorDashboardDataType.ThisWeek.toString(), Count: totalCountThisWeek },
      { Period: PresenceMonitorDashboardDataType.LastWeek.toString(), Count: totalCountLastWeek },
    ];
  }, [data]);

  const presenceMonitorMetricsData: PresenceMonitorMetricsResponse = useMemo(() => {
    const dayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const thisWeekData = data?.find((entry) => entry.Week === Weeks.ThisWeek);
    const lastWeekData = data?.find((entry) => entry.Week === Weeks.LastWeek);

    return dayNames.map((day, index) => ({
      Name: day,
      LastWeek: lastWeekData ? lastWeekData.DayCounts[index] : 0,
      ThisWeek: thisWeekData ? thisWeekData.DayCounts[index] : 0,
    }));
  }, [data]);

  // Props
  const getPresenceMonitorStatsProps = useCallback(
    () => ({
      content: presenceMonitorStats?.map((item) => getStat(item)),
    }),
    [presenceMonitorStats]
  );

  const getPresenceMonitorMetricsProps = useCallback(
    () => ({
      presenceMonitorMetrics: presenceMonitorMetricsData,
    }),
    [presenceMonitorMetricsData]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch();
  }, [fetch]);

  return useMemo(
    () => ({
      getPresenceMonitorStatsProps,
      getPresenceMonitorMetricsProps,
    }),
    [getPresenceMonitorStatsProps, getPresenceMonitorMetricsProps]
  );
};
