import { AxiosResponse } from 'axios';

import { Employee } from '../models/Employee';
import { Visitor } from '../models/Visits/Visitor';
import { TenantData } from '../models/TenantData';
import { IdentifierType } from '../models/IdentifierType';
import { ApiResponse } from '../models/ApiResponse';
import { AccessProfile } from '../models/AccessProfile';
import { apiService } from '../services/ApiService/ApiService';
import { VisitorDashboardResponse } from '../models/Visits/Dashboard/VisitorDashboardResponse';
import { Guest } from '../models/Visits/Guest';
import { TenantSettings } from '../models/TenantSettings';
import { Action } from '../models/Action';
import { UserMapping } from '../models/Visits/UserMapping';
import { AuthorizedUserAccessProfile } from '../models/AccessProfileVerification';
import { Area, Person, YearlyHistoricalData } from '../models/PresenceMonitor/Person';

const autoResolving =
  <A extends any[], T>(fn: (...a: A) => Promise<AxiosResponse<ApiResponse<T>>>) =>
  async (...args: A): Promise<ApiResponse<T>> =>
    (await fn(...args)).data;

export const ApiEndpoints = {
  // Tenant
  tenant: {
    data: autoResolving(() =>
      apiService.execute<ApiResponse<TenantData>>({
        url: 'Tenants/Data',
      })
    ),
    identifierTypes: autoResolving(() =>
      apiService.execute<ApiResponse<Array<IdentifierType>>>({
        url: 'Tenants/IdentifierTypes',
      })
    ),
    accessProfiles: autoResolving(() =>
      apiService.execute<ApiResponse<Array<AccessProfile>>>({
        url: 'Tenants/AccessProfiles',
      })
    ),
  },

  // Employees
  employees: {
    list: autoResolving(({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search }) =>
      apiService.execute<ApiResponse<Array<Employee>>>({
        url: 'Employees',
        params: { index, size, orderBy, desc, search },
      })
    ),
    detail: autoResolving(({ id }) =>
      apiService.execute<ApiResponse<Employee>>({
        url: `Employees/${id}`,
      })
    ),
    csvExport: autoResolving(() =>
      apiService.execute<ApiResponse<Blob>>({
        url: `Employees/Import`,
      })
    ),
  },

  // Visitors
  visitors: {
    list: autoResolving(
      ({
        pageIndex: index,
        pageSize: size,
        sortedBy: orderBy,
        desc,
        search,
        timeframe,
        visitType,
        customStartDate,
        customEndDate,
      }) =>
        apiService.execute<ApiResponse<Array<Visitor>>>({
          url: 'Visitors',
          params: { index, size, orderBy, desc, search, timeframe, visitType, customStartDate, customEndDate },
        })
    ),
    detail: autoResolving(({ id }) =>
      apiService.execute<ApiResponse<Visitor>>({
        url: `Visitors/${id}`,
      })
    ),
    dashboard: autoResolving(() =>
      apiService.execute<ApiResponse<VisitorDashboardResponse>>({
        url: `Dashboard/Data/Visitors`,
      })
    ),
  },

  // Guests
  guests: {
    getByQrCode: autoResolving(({ qrCode }) =>
      apiService.execute<ApiResponse<Guest>>({
        url: `Guests/GetByQrCode/${qrCode}`,
      })
    ),
    getByPincode: autoResolving(({ pincode }) =>
      apiService.execute<ApiResponse<Guest>>({
        url: `Guests/GetByPincode/${pincode}`,
      })
    ),
  },

  // Reservations
  reservations: {
    detail: autoResolving(() =>
      apiService.execute<ApiResponse<Visitor>>({
        url: `Reservations`,
      })
    ),
    settings: autoResolving(() =>
      apiService.execute<ApiResponse<TenantSettings>>({
        url: 'Reservations/Settings',
      })
    ),
    identifierTypes: autoResolving(() =>
      apiService.execute<ApiResponse<Array<IdentifierType>>>({
        url: 'Reservations/IdentifierTypes',
      })
    ),
  },

  // Actions
  Actions: {
    list: autoResolving(({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search } = {}) =>
      apiService.execute<ApiResponse<Array<Action>>>({
        url: 'Actions/ForTenant',
        params: { index, size, orderBy, desc, search },
      })
    ),
    detail: autoResolving(({ id }) =>
      apiService.execute<ApiResponse<Action>>({
        url: `Actions/${id}`,
      })
    ),
  },

  // UserMapping
  UserMapping: {
    userMapping: autoResolving(({ id }) =>
      apiService.execute<ApiResponse<Array<UserMapping>>>({
        url: `ServiceConfigurations/getMappingsForUser/${id}`,
      })
    ),
  },

  // Verification
  accessProfileVerification: {
    authorizedUserAccessProfiles: autoResolving(
      ({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search, personType }) =>
        apiService.execute<ApiResponse<Array<AuthorizedUserAccessProfile>>>({
          url: 'AuthorizedUserAccessProfiles',
          params: { index, size, orderBy, desc, search, personType },
        })
    ),
    authorizedUserAccessProfilesCount: autoResolving(
      ({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search, personType }) =>
        apiService.execute<ApiResponse<number>>({
          url: 'AuthorizedUserAccessProfiles/Count',
          params: { index, size, orderBy, desc, search, personType },
        })
    ),
  },

  // PresenceMonitor
  PresenceMonitor: {
    list: autoResolving(({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search, areaId, personType }) =>
      apiService.execute<ApiResponse<Array<Person>>>({
        url: 'Transactions',
        params: { index, size, orderBy, desc, search, areaId, personType },
      })
    ),
    areaList: autoResolving(({ pageIndex: index, pageSize: size, sortedBy: orderBy, desc, search } = {}) =>
      apiService.execute<ApiResponse<Array<Area>>>({
        url: 'Areas',
        params: { index, size, orderBy, desc, search },
      })
    ),
    GetWeeklyHistoricalData: autoResolving(({ years } = {}) =>
      apiService.execute<ApiResponse<Array<YearlyHistoricalData>>>({
        url: 'Transactions/Weekly',
        params: { years },
      })
    ),
  },
};
