import React, { useCallback, useMemo, useState } from 'react';
import { ConfigProvider, DatePicker, Select } from 'antd';

// Models
import { useDispatch } from 'react-redux';
import moment, { Moment } from 'moment/moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from 'rc-picker/lib/interface';
import locale from 'antd/es/locale/en_GB';
import styled from 'styled-components';
import { Timeframe } from '../../models/enums/Timeframe';

// Components
import { Translated } from '../../components/UI/Core';
import { useAppSelector } from '../App/useRedux';
import { updateCustomTableFilters } from '../../store/Visitors/Visitors.redux';
import { VisitType } from '../../models/enums/VisitType';
import { dateStringFormat, getLocalDateTime } from '../../constants/Utils/DateTime';

// Components
const { RangePicker } = DatePicker;

// Styled
const StyledRangePicker = styled(RangePicker)`
  .ant-picker-ok {
    margin-bottom: 0;
  }
`;

moment.updateLocale('en-gb', { week: { dow: 1 } });

// Data
const timeframeOptions = [
  {
    value: Timeframe.Today,
    label: <Translated id="visitors.dashboard.today" />,
  },
  {
    value: Timeframe.Tomorrow,
    label: <Translated id="visitors.dashboard.tomorrow" />,
  },
  {
    value: Timeframe.ThisWeek,
    label: <Translated id="visitors.dashboard.thisWeek" />,
  },
  {
    value: Timeframe.NextWeek,
    label: <Translated id="visitors.dashboard.nextWeek" />,
  },
  {
    value: Timeframe.LastWeek,
    label: <Translated id="visitors.dashboard.lastWeek" />,
  },
  {
    value: Timeframe.ThisMonth,
    label: <Translated id="visitors.dashboard.thisMonth" />,
  },
  {
    value: Timeframe.ThisYear,
    label: <Translated id="visitors.dashboard.thisYear" />,
  },
  {
    value: Timeframe.AllTime,
    label: <Translated id="visitors.dashboard.allTime" />,
  },
  {
    value: Timeframe.Custom,
    label: <Translated id="visitors.dashboard.custom" />,
  },
];

const visitTypeOptions = [
  {
    value: VisitType.All,
    label: <Translated id="visitors.visitType.all" />,
  },
  {
    value: VisitType.Default,
    label: <Translated id="visitors.visitType.default" />,
  },
  {
    value: VisitType.Reservation,
    label: <Translated id="visitors.visitType.reservation" />,
  },
];

// Hook
export const useVisitorsTableFilter = () => {
  // State
  const dispatch = useDispatch();
  const { customTableFilters } = useAppSelector(({ visitors }) => visitors);
  const [filterValues, setFilterValues] = useState(customTableFilters);

  // Components
  const FilterSelect = useCallback(
    () => (
      <div>
        <Select
          value={filterValues.visitType}
          options={visitTypeOptions}
          onChange={(value) => {
            setFilterValues({
              ...filterValues,
              visitType: value,
            });
            dispatch(updateCustomTableFilters({ ...filterValues, visitType: value }));
          }}
          style={{ width: 140, marginRight: 16, marginLeft: 16 }}
        />
        <Select
          value={filterValues.timeframe}
          options={timeframeOptions}
          onChange={(value) => {
            setFilterValues({
              ...filterValues,
              timeframe: value,
            });
            dispatch(updateCustomTableFilters({ ...filterValues, timeframe: value }));
          }}
          style={{ width: 140, marginRight: 16 }}
        />
        {filterValues.timeframe === Timeframe.Custom && (
          <ConfigProvider locale={locale}>
            <StyledRangePicker
              id="visitors-table-date-picker"
              defaultValue={[
                getLocalDateTime(filterValues.customStartDate),
                getLocalDateTime(filterValues.customEndDate),
              ]}
              onChange={(values: RangeValue<Moment>) => {
                const [from, to] = values || [];

                if (from && to) {
                  setFilterValues({
                    ...filterValues,
                    customStartDate: from.hours(0).minutes(0).toISOString(),
                    customEndDate: to.hours(23).minutes(59).toISOString(),
                  });
                  dispatch(
                    updateCustomTableFilters({
                      ...filterValues,
                      customStartDate: from.hours(0).minutes(0).toISOString(),
                      customEndDate: to.hours(23).minutes(59).toISOString(),
                    })
                  );
                }
              }}
              format={dateStringFormat}
              style={{ width: 220, marginRight: 16 }}
            />
          </ConfigProvider>
        )}
      </div>
    ),
    [dispatch, filterValues]
  );

  // Hook response
  return useMemo(
    () => ({
      FilterSelect,
      filterValues,
    }),
    [FilterSelect, filterValues]
  );
};
