/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Visitor } from '../../../models/Visits/Visitor';

// Models
import { IdentifierFormValues } from '../../../models/IdentifierFormValues';
import { SignalRAction } from '../../../models/SignalRAction';

// State
interface VisitorIdentifiersState {
  updating: boolean;
  error: boolean;
}

const initialState: VisitorIdentifiersState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@VISITOR_IDENTIFIERS';

// Redux Actions|Reducers
const visitorIdentifiersSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Assign
    assignIdentifier: (state, _action: PayloadAction<{ formValues: IdentifierFormValues; visitor: Visitor }>) => {
      state.updating = true;
      state.error = false;
    },
    identifierAssigned: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    identifierNotAssigned: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
    // Remove
    removeIdentifier: (state, _action: PayloadAction<{ formValues: IdentifierFormValues; visitor: Visitor }>) => {
      state.updating = true;
      state.error = false;
    },
    identifierRemoved: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    identifierNotRemoved: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
    identifiersNotRemoved: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
    // Toggle
    toggleIdentifierStatus: (state, _action: PayloadAction<{ formValues: IdentifierFormValues; visitor: Visitor }>) => {
      state.updating = true;
      state.error = false;
    },
    identifierStatusToggled: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    identifierStatusNotToggled: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Assign
  assignIdentifier,
  identifierAssigned,
  identifierNotAssigned,
  // Remove
  removeIdentifier,
  identifierRemoved,
  identifierNotRemoved,
  identifiersNotRemoved,
  // Toggle
  toggleIdentifierStatus,
  identifierStatusToggled,
  identifierStatusNotToggled,
} = visitorIdentifiersSlice.actions;

// Export Reducer
export default visitorIdentifiersSlice.reducer;
