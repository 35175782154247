import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import {
  authorizedUserFileDeleted,
  authorizedUserFileNotDeleted,
  authorizedUserFileNotUpdated,
  authorizedUserFileUpdated,
  deleteAuthorizedUserFile,
  updateAuthorizedUserFile,
} from './AuthorizedUserProfilePicture.redux';
import { AuthorizedUserProfilePicture } from '../../models/AuthorizedUserProfilePicture';

// Update file

function* updateAuthorizedUserFileSaga() {
  yield takeEvery(updateAuthorizedUserFile.type, updateAuthorizedUserFileRequest);
}

function* authorizedUserFileUpdatedSaga() {
  yield takeLatest(authorizedUserFileUpdated.type, updateAuthorizedUserFileResponse);
}

function* authorizedUserFileNotUpdatedSaga() {
  yield takeLatest(authorizedUserFileNotUpdated.type, updateAuthorizedUserFileError);
}

// Request
function* updateAuthorizedUserFileRequest(action: PayloadAction<AuthorizedUserProfilePicture>) {
  try {
    const formData = new FormData();
    const { payload: authorizedUser } = action;
    formData.append('FormFile', authorizedUser.Media.FormFile);

    yield apiService.execute({
      url: `AuthorizedUserProfilePicture/${authorizedUser.Id}/files`,
      method: ApiRequestType.POST,
      data: formData,
    });
  } catch ({ message }) {
    yield put({ type: authorizedUserFileNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateAuthorizedUserFileResponse() {
  notificationService.showSuccess('profile.picture.updated.success');
}

// Error
function updateAuthorizedUserFileError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('profile.picture.updated.error', action?.payload?.msg.message);
}

// Delete file
function* deleteAuthorizedUserFileSaga() {
  yield takeEvery(deleteAuthorizedUserFile.type, deleteAuthorizedUserFileRequest);
}

function* authorizedUserFileDeletedSaga() {
  yield takeLatest(authorizedUserFileDeleted.type, deleteAuthorizedUserFileResponse);
}

function* authorizedUserFileNotDeletedSaga() {
  yield takeLatest(authorizedUserFileNotDeleted.type, deleteAuthorizedUserFileError);
}

// Request
function* deleteAuthorizedUserFileRequest(action: PayloadAction<AuthorizedUserProfilePicture>) {
  try {
    const { payload: authorizedUser } = action;
    yield apiService.execute({
      url: `AuthorizedUserProfilePicture/${authorizedUser.Id}/file`,
      method: ApiRequestType.DELETE,
      data: authorizedUser,
    });
  } catch ({ message }) {
    yield put({ type: authorizedUserFileNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteAuthorizedUserFileResponse() {
  notificationService.showSuccess('profile.picture.deleted.success');
}

// Error
function deleteAuthorizedUserFileError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('profile.picture.deleted.error', action?.payload?.msg.message);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Update file
    updateAuthorizedUserFileSaga(),
    authorizedUserFileUpdatedSaga(),
    authorizedUserFileNotUpdatedSaga(),
    // Delete File
    deleteAuthorizedUserFileSaga(),
    authorizedUserFileDeletedSaga(),
    authorizedUserFileNotDeletedSaga(),
  ]);
}
