export type PresenceMonitorStat = {
  Period: string;
  Count: number;
};
export type PresenceMonitorStatsResponse = Array<PresenceMonitorStat>;
export type PresenceMonitorResponse = {
  Total: number;
};
export type PresenceMonitorMetricsResponse = Array<{
  Name: string;
  LastWeek: number;
  ThisWeek: number;
}>;

export type PresenceMonitorDashboardResponse = {
  PresenceMonitorStats: PresenceMonitorStatsResponse;
  PresenceMonitor: PresenceMonitorResponse;
  PresenceMonitorMetrics: PresenceMonitorMetricsResponse;
};

export enum Weeks {
  ThisWeek = 0,
  LastWeek = 1,
}
