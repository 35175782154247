import React from 'react';

// Constants
import { Translated } from '../../components/UI/Core';
import { BreadcrumbIcon } from '../../components/UI/Icon/BreadcrumbIcon';
import { getBreadcrumbMenuItems } from '../../components/UI/Breadcrumb/BreadcrumbMenu';

export const appBreadcrumbs = {
  visitors: {
    list: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.visitors" />,
        icon: <BreadcrumbIcon className="icon icon-avatar" />,
      },
    ]),
    detail: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.visitors" />,
        icon: <BreadcrumbIcon className="icon icon-avatar" />,
        link: '/Visitors',
      },
      {
        label: <Translated id="app.pages.details" />,
        icon: <BreadcrumbIcon className="icon icon-ckeditor" />,
      },
    ]),
  },
  employeees: {
    list: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.employees" />,
        icon: <BreadcrumbIcon className="icon icon-auth-screen" />,
      },
    ]),
    detail: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.employees" />,
        icon: <BreadcrumbIcon className="icon icon-auth-screen" />,
        link: '/Employees',
      },
      {
        label: <Translated id="app.pages.details" />,
        icon: <BreadcrumbIcon className="icon icon-ckeditor" />,
      },
    ]),
  },
  verification: {
    list: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.verification" />,
        icon: <BreadcrumbIcon className="icon icon-wysiwyg" />,
      },
    ]),
  },
  presenceMonitor: {
    list: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.presenceMonitor" />,
        icon: <BreadcrumbIcon className="icon icon-data-display" />,
      },
    ]),
  },
};
