import axios, { AxiosResponse, Method } from 'axios';

import { ApiGateway } from '../../constants/Settings/ApiSettings';
import { authService } from '../ApiAuthorization/AuthorizeService';

interface ExecuteApiRequest {
  method?: Method;
  url: string;
  params?: unknown;
  data?: unknown;
}

class ApiService {
  static execute = async <T>({ method = ApiRequestType.GET, url, params, data }: ExecuteApiRequest) => {
    const token = await authService.getAccessToken();
    return axios({
      method,
      url,
      params,
      paramsSerializer: {
        indexes: null,
      },
      data,
      baseURL: `${ApiGateway}`,
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
    }) as Promise<AxiosResponse<T>>;
  };
}

export const apiService = ApiService;

export const ApiRequestType = {
  GET: 'get' as Method,
  POST: 'post' as Method,
  PUT: 'put' as Method,
  PATCH: 'patch' as Method,
  DELETE: 'delete' as Method,
};
