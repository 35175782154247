import React, { useCallback, useEffect, useState } from 'react';
import ImgCrop from 'antd-img-crop';
import { Upload, UploadFile } from 'antd';
import styled from 'styled-components';
import { useAppDispatch } from '../../hooks/App/useRedux';
import {
  deleteAuthorizedUserFile,
  updateAuthorizedUserFile,
} from '../../store/AuthorizedUserProfilePicture/AuthorizedUserProfilePicture.redux';

const StyledUpload = styled(Upload)`
  .ant-upload-list-item {
    border-radius: 50% !important;
    overflow: hidden;
    width: 90px;
    height: 90px;
  }

  .ant-upload.ant-upload-select-picture-card {
    border-radius: 6px !important;
    background-color: transparent;
    height: 30px;
  }

  .ant-upload-list-picture-card-container {
    margin: 0;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-info {
    border-radius: 45px !important;
  }

  .ant-upload-list {
    display: flex;
    flex-direction: column;
  }

  .upload-button {
    text-align: center;
    color: white;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: transparent;
  }
`;

// Default image URL
const defaultImage = {
  uid: '-1',
  name: 'profile.png',
  url: 'https://as2.ftcdn.net/jpg/02/60/78/83/500_F_260788352_x5sSHM4DGvpjHj9wz8sFltzAPktQwJCj.jpg',
};

const getSrcFromFile = (file: UploadFile) => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.originFileObj!);
    reader.onload = () => resolve(reader.result as string);
  });
};

interface AuthorizedUserProfilePictureBlobProps {
  AuthorizedUserId: string | undefined;
  Url: string | undefined;
}

const UploadImage = ({ AuthorizedUserId, Url }: AuthorizedUserProfilePictureBlobProps) => {
  const [files, setFileList] = useState<UploadFile[]>([defaultImage]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Set the initial state based on the Url prop (if available)
    if (Url) {
      setFileList([
        {
          uid: '1',
          name: 'profile_image',
          url: Url,
        },
      ]);
    } else {
      setFileList([defaultImage]); // Fallback to default image if no Url is provided
    }
  }, [Url]);

  const onChange = ({ fileList }: { fileList: UploadFile[] }) => {
    // Filter out the default image if a new file is uploaded
    const updatedFileList = fileList.filter((file) => file.uid !== defaultImage.uid);

    // Keep only the last uploaded image if there are multiple
    const finalFileList = updatedFileList.length > 1 ? updatedFileList.slice(-1) : updatedFileList;

    // If fileList becomes empty after filtering, reset to default image
    setFileList(finalFileList.length === 0 ? [defaultImage] : finalFileList);
  };

  const onPreview = async (file: UploadFile) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const beforeUpload = useCallback(
    (file?: File) => {
      if (file) {
        dispatch(
          updateAuthorizedUserFile({
            Id: AuthorizedUserId,
            Media: { FormFile: file, Url: '', MimeType: '' },
          })
        );
      }
    },
    [AuthorizedUserId, dispatch]
  );

  const onRemove = (file: UploadFile) => {
    // If the file is the only one in the list, reset to the default image
    if (file.uid === '1') {
      const formFile: File = file.originFileObj as File;

      setFileList([defaultImage]);
      dispatch(
        deleteAuthorizedUserFile({
          Id: AuthorizedUserId,
          Media: { FormFile: formFile, Url: '', MimeType: '' },
        })
      );
    }
  };

  return (
    <ImgCrop showGrid rotationSlider aspectSlider showReset>
      <StyledUpload
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        listType="picture-card"
        onChange={onChange}
        fileList={files}
        onPreview={onPreview}
        beforeUpload={beforeUpload}
        onRemove={onRemove} // Use the built-in onRemove handler
      >
        <div className="upload-button">
          <span>+ Upload</span>
        </div>
      </StyledUpload>
    </ImgCrop>
  );
};

export default UploadImage;
