import React, { useCallback, useEffect, useMemo } from 'react';
import { Select } from 'antd';

// Models
import { useDispatch } from 'react-redux';

// Components
import { Translated } from '../../components/UI/Core';
import { useAppSelector } from '../App/useRedux';
import { updateCustomTableFilters } from '../../store/PresenceMonitor/PresenceMonitor.redux';
import { PersonType } from '../../models/enums/PersonType';
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { useData } from '../App/useData';

// Data
const personTypeOptions = [
  {
    value: PersonType.Default,
    label: <Translated id="presenceMonitor.person.type.all" />,
  },
  {
    value: PersonType.Visitor,
    label: <Translated id="presenceMonitor.person.type.visitor" />,
  },
  {
    value: PersonType.Employee,
    label: <Translated id="presenceMonitor.person.type.employee" />,
  },
];

// Hook
export const usePresenceMonitorTableFilter = () => {
  // State
  const dispatch = useDispatch();
  const { customTableFilters } = useAppSelector(({ presenceMonitor }) => presenceMonitor);

  const { data: areas, fetch } = useData(ApiEndpoints.PresenceMonitor.areaList, null);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const areasOptions = useMemo(
    () => [
      { value: '', label: 'All Areas' },
      ...(areas?.map((area) => ({
        value: area.Id,
        label: area.Name,
      })) || []),
    ],
    [areas]
  );

  // Components
  const FilterSelect = useCallback(
    () => (
      <div>
        <Select
          value={customTableFilters.personType}
          options={personTypeOptions}
          onChange={(value) => dispatch(updateCustomTableFilters({ ...customTableFilters, personType: value }))}
          style={{ width: 140, marginRight: 16 }}
        />
        <Select
          value={customTableFilters.areaId}
          options={areasOptions}
          onChange={(value) => dispatch(updateCustomTableFilters({ ...customTableFilters, areaId: value }))}
          style={{ width: 140, marginRight: 16 }}
        />
      </div>
    ),
    [customTableFilters, areasOptions, dispatch]
  );

  // Hook response
  return useMemo(
    () => ({
      FilterSelect,
      customTableFilters,
    }),
    [FilterSelect, customTableFilters]
  );
};
