import React, { useCallback, useMemo } from 'react';

// Hooks
import { useData } from '../App/useData';
import { usePresenceMonitorTableFilter } from './usePresenceMonitorTableFilter';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

// Constants
import { LocalStorageKeys } from '../../constants/Utils/LocalStorage';
import { getTableColumns, saveHiddenTableColumns } from '../../constants/Utils/UIFunctions';

// Components
import { Translated } from '../../components/UI/Core';
import { BasicIcon } from '../../components/UI/Icon/BasicIcon';
import { presenceMonitorTableColumns } from '../../data/Table/presenceMonitorTableColumns';

export const usePresenceMonitorList = <T extends object>() => {
  // Data
  const { data: persons, pageCount, totalCount, fetch, pending } = useData(ApiEndpoints.PresenceMonitor.list, null);

  // Table Filter
  const { FilterSelect, customTableFilters } = usePresenceMonitorTableFilter();

  // Fetch + Append Filter Data
  const fetchData = useCallback((args: any) => fetch({ ...args, ...customTableFilters }), [fetch, customTableFilters]);

  const getPresenceMonitor = useCallback(
    () =>
      persons?.map((person) => ({
        ...person,
      })),
    [persons]
  );

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.presenceMonitor.list }), []);
  const getPresenceMonitorDataProps = useCallback(
    () => ({
      storageKey: LocalStorageKeys.PresenceMonitor.table.hiddenColumns,
      title: (
        <>
          <BasicIcon className="icon icon-data-display" style={{ marginRight: 8 }} />
          <Translated id="appMenu.presenceMonitor" />
        </>
      ),
      data: getPresenceMonitor() ?? [],
      columns: getTableColumns(presenceMonitorTableColumns, LocalStorageKeys.PresenceMonitor.table.hiddenColumns),
      fetchData,
    }),
    [fetchData, getPresenceMonitor]
  );
  const getPresenceMonitorTableProps = useCallback(
    () => ({
      height: 320,
      pending,
      updating: false,
      pageCount,
      totalCount,
    }),
    [pending, pageCount, totalCount]
  );
  const getPresenceMonitorCrudProps = useCallback(
    () => ({
      saveHiddenTableColumns,
      FilterSelect,
      onRowClick: () => null,
    }),
    [FilterSelect]
  );

  return useMemo(
    () => ({
      getBreadcrumbMenuProps,
      getPresenceMonitorDataProps,
      getPresenceMonitorTableProps,
      getPresenceMonitorCrudProps,
    }),
    [getBreadcrumbMenuProps, getPresenceMonitorDataProps, getPresenceMonitorTableProps, getPresenceMonitorCrudProps]
  );
};
