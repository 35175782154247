import moment from 'moment/moment';
import { Timeframe } from '../enums/Timeframe';

export enum ReportFormat {
  Xlsx,
  Pdf,
  Csv,
}

export const defaultReport: VisitReportFormValues = {
  FieldsToExport: [
    'Status',
    'Email',
    'Company',
    'Timeframe',
    'Name',
    'PhoneNumber',
    'Identifiers',
    'Area',
    'EnergyConsumption',
  ],
  ReportFormat: ReportFormat.Xlsx,
  Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  Timeframe: Timeframe.ThisMonth,
  CustomStartDate: moment().hour(0).minutes(0).toISOString(),
  CustomEndDate: moment().add(7, 'days').hour(23).minutes(59).toISOString(),
};

export interface VisitReportFormValues {
  FieldsToExport: string[];
  ReportFormat: ReportFormat;
  Timezone: string;
  Timeframe: Timeframe;
  CustomStartDate: string;
  CustomEndDate: string;
}
