export enum Timeframe {
  Invalid = -1,
  AllTime = 0,
  Today = 1,
  Tomorrow = 2,
  ThisWeek = 3,
  NextWeek = 4,
  LastWeek = 5,
  ThisMonth = 6,
  ThisYear = 7,
  Custom = 8,
}
