import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Space,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import locale from 'antd/es/locale/en_GB';
import { Moment } from 'moment/moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from 'rc-picker/lib/interface';
import { Translated } from '../../components/UI/Core';
import { StyledTooltip } from '../../components/UI/Tooltip/StyledTooltip';
import { defaultReport, ReportFormat } from '../../models/Visits/VisitReportFormValues';
import { Timeframe } from '../../models/enums/Timeframe';
import { downloadReport } from '../../services/Report/ReportService';
import { dateStringFormat, getLocalDateTime } from '../../constants/Utils/DateTime';

// Components
const { RangePicker } = DatePicker;

const StyledButton = styled(Button)`
  padding: 5px 10px;
  margin-bottom: 0;
  display: flex;
  align-items: center;

  :focus,
  :active,
  :active:focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;

const StyledRangePicker = styled(RangePicker)`
  .ant-picker-ok {
    margin-bottom: 0;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Hook
export const useDownloadReportButton = () => {
  // State
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setOpen(true);
  };

  const handleDownload = useCallback(async () => {
    try {
      const formValues = await form.validateFields();

      let query = 'Reports/Visitors';
      query += `?ReportFormat=${formValues.ReportFormat}`;
      query += `&Timeframe=${formValues.Timeframe}`;
      query += `&Timezone=${formValues.Timezone}`;

      if (formValues.CustomStartDate) {
        query += `&CustomStartDate=${formValues.CustomStartDate}`;
      }
      if (formValues.CustomEndDate) {
        query += `&CustomEndDate=${formValues.CustomEndDate}`;
      }

      formValues.FieldsToExport.forEach((field: string) => {
        query += `&FieldsToExport=${field}`;
      });

      await downloadReport(query);
    } catch (e) {
      return false;
    }
    return true;
  }, [form]);

  const handleCancel = () => {
    setOpen(false);
  };

  // Components
  const DownloadReport = useCallback(
    () => (
      <>
        <StyledTooltip title={<Translated id="reports.visitors.exportButton" />} placement="top">
          <StyledButton onClick={showModal}>
            <DownloadOutlined />
          </StyledButton>
        </StyledTooltip>
        <Modal
          okText={<Translated id="form.downloadButton" />}
          cancelText={<Translated id="form.cancelButton" />}
          title={<Translated id="reports.visitors.exportData" />}
          open={open}
          onOk={handleDownload}
          onCancel={handleCancel}
        >
          <StyledForm layout="vertical" form={form} initialValues={defaultReport}>
            <Form.Item name="Timezone" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="CustomStartDate" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="CustomEndDate" hidden>
              <Input />
            </Form.Item>

            <Form.Item name="FieldsToExport" label={<Translated id="reports.fieldsToExport" />}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row dir="row" style={{ flexDirection: 'row' }}>
                  <Col span={12}>
                    <Checkbox value="Status" style={{ lineHeight: '32px' }}>
                      <Translated id="visits.info.status" />
                    </Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="Name" style={{ lineHeight: '32px' }}>
                      <Translated id="visitors.fullName" />
                    </Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="Email" style={{ lineHeight: '32px' }}>
                      <Translated id="visitors.email" />
                    </Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="PhoneNumber" style={{ lineHeight: '32px' }}>
                      <Translated id="visitors.phone" />
                    </Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="Company" style={{ lineHeight: '32px' }}>
                      <Translated id="visitors.companyName" />
                    </Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="Identifiers" style={{ lineHeight: '32px' }}>
                      <Translated id="visitors.identifiers" />
                    </Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="Timeframe" style={{ lineHeight: '32px' }}>
                      <Translated id="visits.info.time" />
                    </Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="EnergyConsumption" style={{ lineHeight: '32px' }}>
                      <Translated id="visits.energyConsumption" />
                    </Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="Area" style={{ lineHeight: '32px' }}>
                      <Translated id="visitors.area" />
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>

            <Divider />

            <Form.Item name="ReportFormat" label={<Translated id="reports.reportFormat" />}>
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={ReportFormat.Xlsx}>
                    <Translated id="reports.excel" />
                  </Radio>
                  <Radio value={ReportFormat.Pdf}>
                    <Translated id="reports.pdf" />
                  </Radio>
                  <Radio value={ReportFormat.Csv}>
                    <Translated id="reports.csv" />
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>

            <Divider />

            <Form.Item name="Timeframe" label={<Translated id="visits.info.time" />}>
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={Timeframe.Today}>
                    <Translated id="app.today" />
                  </Radio>
                  <Radio value={Timeframe.ThisWeek}>
                    <Translated id="app.thisWeek" />
                  </Radio>
                  <Radio value={Timeframe.ThisMonth}>
                    <Translated id="app.thisMonth" />
                  </Radio>
                  <Radio value={Timeframe.AllTime}>
                    <Translated id="visitors.dashboard.allTime" />
                  </Radio>
                  <Radio value={Timeframe.Custom}>
                    <Translated id="visitors.dashboard.custom" />
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>

            <Form.Item shouldUpdate>
              {() =>
                form.getFieldValue('Timeframe') === Timeframe.Custom && (
                  <ConfigProvider locale={locale}>
                    <StyledRangePicker
                      id="visitors-table-date-picker"
                      defaultValue={[
                        getLocalDateTime(form.getFieldValue('CustomStartDate')),
                        getLocalDateTime(form.getFieldValue('CustomEndDate')),
                      ]}
                      onChange={(values: RangeValue<Moment>) => {
                        const [from, to] = values || [];

                        if (from && to) {
                          form.setFields([
                            {
                              name: 'CustomStartDate',
                              value: from.hours(0).minutes(0).toISOString(),
                            },
                            {
                              name: 'CustomEndDate',
                              value: to.hours(23).minutes(59).toISOString(),
                            },
                          ]);
                        }
                      }}
                      format={dateStringFormat}
                      style={{ width: 220, marginRight: 16 }}
                    />
                  </ConfigProvider>
                )
              }
            </Form.Item>
          </StyledForm>
        </Modal>
      </>
    ),
    [form, handleDownload, open]
  );

  // Hook response
  return useMemo(() => ({ DownloadReportButton: DownloadReport }), [DownloadReport]);
};
