import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { usePrevious } from 'react-use';
import {
  ColumnInstance,
  IdType,
  Row,
  TableBodyPropGetter,
  TableBodyProps as ReactTableBodyProps,
  useExpanded,
  useFilters,
  useFlexLayout,
  usePagination as useReactTablePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

// Types
import { isEmpty } from 'lodash';
import { TableProps } from '../../types/Table';

// Hooks
import { HiddenColumnsProps, useHiddenColumns } from './useHiddenColumns';
import { useSelectedRows } from './useSelectedRows';
import { usePagination } from './usePagination';

// Components
import { getSelectionColumn } from '../../components/UI/Table/elements/TableSelectionColumn';
import { TableHeadColumnSearch } from '../../components/UI/Table/elements/TableHeadColumnSearch';
import { getExpansionColumn } from '../../components/UI/Table/elements/TableExpansionColumn';

// Props
export interface TableTitleProps {
  title?: React.ReactNode;
  hideDeleteButton: boolean;
  AddButton?: () => React.ReactElement;
  EditButton: () => React.ReactElement | null;
  UploadButton?: () => React.ReactElement | null;
  DeleteButton?: () => React.ReactElement | null;
  FilterSelect?: () => React.ReactElement | null;
  DownloadReportButton?: () => React.ReactElement | null;
  ColumnsToggle?: () => React.ReactElement;
}

export interface TableHeadProps<T extends object> {
  columns: ColumnInstance<T>[];
  getHiddenColumnsProps: () => HiddenColumnsProps<T>;
}

export interface TableBodyProps<T extends object> {
  rows: Array<Row<T>>;
  pending: boolean;
  columnCount: number;
  showAllColumns: boolean;
  onRowClick: (id: string) => {
    onClick: () => void;
  };
  getTableBodyProps: (propGetter?: TableBodyPropGetter<T>) => ReactTableBodyProps;
  prepareRow: (row: Row<T>) => void;
}

export interface TableActionsProps {
  PageChanger: () => React.ReactElement;
  PageSizeChanger: () => React.ReactElement;
  PageItemsCount: () => React.ReactElement;
}

// Hook
export const useTableProps = <T extends object>({
  // DataProps
  storageKey,
  title,
  data,
  columns,
  fetchData,
  // DataProps
  pending,
  updating,
  lastEventId,
  pageCount: controlledPageCount,
  totalCount: controlledTotalCount,
  // CrudProps
  endpoint,
  AddButton,
  UploadButton,
  FilterSelect,
  DownloadReportButton,
  // Event Handlers
  onRowClick,
  // Options
  saveHiddenTableColumns,
  hideDeleteButton,
  hideSelectableRows,
  showDeleteAllConfirm,
  showEditForm,
  showExpandableRows,
}: TableProps<T>) => {
  // Hooks
  const prevUpdating = usePrevious(updating);
  const prevLastEventId = usePrevious(lastEventId);
  const navigate = useNavigate();

  // Filter
  const defaultColumn = useMemo(() => ({ Filter: TableHeadColumnSearch }), []);

  // React Table Configuration
  const {
    headers,
    rows,
    allColumns,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    // Hiding
    setHiddenColumns,
    // Pagination
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Row Selection
    selectedFlatRows,
    // Row Expansion
    toggleAllRowsExpanded,
    // Instance State
    state: { sortBy, pageIndex, pageSize, filters, hiddenColumns },
  } = useTable<T>(
    {
      columns,
      data,
      initialState: {
        sortBy: [],
        pageIndex: 0,
        pageSize: 50,
        filters: [],
        hiddenColumns: columns.flatMap((col) => (col.isVisible === false ? [col.id as IdType<T>] : [])),
      },
      manualSortBy: true,
      disableMultiSort: true,
      manualPagination: true,
      pageCount: controlledPageCount,
      manualFilters: true,
      defaultColumn,
    },
    useFlexLayout,
    useFilters,
    useSortBy,
    useExpanded,
    useReactTablePagination,
    useRowSelect,
    (hooks) => (!hideSelectableRows ? hooks.visibleColumns.push((cols) => [getSelectionColumn(), ...cols]) : null),
    (hooks) => (showExpandableRows ? hooks.columns.push((cols) => [getExpansionColumn(), ...cols]) : null)
  );

  // Column Hiding
  const { showAllColumns, columnCount, getHiddenColumnsProps, ColumnsToggle } = useHiddenColumns<T>({
    storageKey,
    allColumns,
    hiddenColumns,
    setHiddenColumns,
    saveHiddenTableColumns,
  });

  // Row Selection
  const { DeleteButton, EditButton } = useSelectedRows<T>({
    selectedFlatRows,
    showDeleteAllConfirm,
    showEditForm,
  });

  // Pagination
  const { PageChanger, PageSizeChanger, PageItemsCount } = usePagination<T>({
    totalCount: controlledTotalCount,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  });

  // Props
  const getTitleProps: () => TableTitleProps = useCallback(
    () => ({
      title,
      AddButton,
      EditButton,
      DeleteButton,
      UploadButton,
      ColumnsToggle,
      FilterSelect,
      DownloadReportButton,
      hideDeleteButton,
    }),
    [
      title,
      AddButton,
      EditButton,
      DeleteButton,
      UploadButton,
      ColumnsToggle,
      FilterSelect,
      DownloadReportButton,
      hideDeleteButton,
    ]
  );

  const getHeadProps: () => TableHeadProps<T> = useCallback(
    () => ({
      columns: showAllColumns ? allColumns : headers,
      getHiddenColumnsProps,
    }),
    [showAllColumns, allColumns, headers, getHiddenColumnsProps]
  );

  const getBodyProps: () => TableBodyProps<T> = useCallback(
    () => ({
      rows,
      pending,
      columnCount,
      showAllColumns,
      onRowClick: (id: string) => ({
        onClick: () => {
          if (onRowClick) onRowClick(id);
          else navigate(`/${endpoint}/${id}`);
        },
      }),
      getTableBodyProps,
      prepareRow,
    }),
    [rows, pending, columnCount, showAllColumns, onRowClick, navigate, endpoint, getTableBodyProps, prepareRow]
  );

  const getActionsProps: () => TableActionsProps = useCallback(
    () => ({
      PageChanger,
      PageSizeChanger,
      PageItemsCount,
    }),
    [PageChanger, PageItemsCount, PageSizeChanger]
  );

  // Effects
  useEffect(() => {
    // Listen for changes in pagination, sorting and/or search to fetch new data
    const [{ id: sortedBy = null, desc = null } = {}] = sortBy;
    const search = JSON.stringify(isEmpty(filters) ? undefined : filters);
    // Fetch new data
    fetchData({ pageIndex, pageSize, sortedBy, desc, search });
  }, [sortBy, pageIndex, pageSize, filters, fetchData]);

  useEffect(() => {
    // Fetch after updating or receiving a new event
    const updated = prevUpdating === true && updating === false;
    const newEvent = prevLastEventId !== lastEventId;
    if (updated || newEvent) {
      const [{ id: sortedBy = null, desc = null } = {}] = sortBy;
      const search = JSON.stringify(isEmpty(filters) ? undefined : filters);

      // Fetch new data
      fetchData({ pageIndex, pageSize, sortedBy, desc, search });
    }
  }, [prevUpdating, updating, prevLastEventId, lastEventId, sortBy, pageIndex, pageSize, filters, fetchData]);

  useEffect(() => {
    // Show rows expanded by default
    if (isEmpty(sortBy)) {
      toggleAllRowsExpanded();
    }
  }, [toggleAllRowsExpanded, sortBy, data]);

  // Return
  return useMemo(
    () => ({
      getTableProps,
      getTitleProps,
      getHeadProps,
      getBodyProps,
      getActionsProps,
    }),
    [getTableProps, getTitleProps, getHeadProps, getBodyProps, getActionsProps]
  );
};
