import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';

// Hooks
// Components
import { Container } from '../../components/UI/Base';
import { Table } from '../../components/UI/Table/Table';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';
import { Widget } from '../../components/UI/Widget/Widget';
import { usePresenceMonitorList } from '../../hooks/PresenceMonitor/usePresenceMonitorList';
import { usePresenceMonitorDashboard } from '../../hooks/PresenceMonitor/Dashboard/usePresenceMonitorDashboard';
import { PresenceMonitorStats } from '../../components/PresenceMonitor/Dashboard/PresenceMonitorStats';
import { PresenceMonitorMetrics } from '../../components/PresenceMonitor/Dashboard/PresenceMonitorMetrics';
import { PresenceMonitorBoard } from '../../components/PresenceMonitor/Dashboard/PresenceMonitorBoard';

// Styled
const PresenceMonitorWidget = styled(Widget)`
  margin-top: 20px;
  margin-bottom: 0px;

  & .ant-card-body {
    padding: 16px 24px;
  }
`;

// Component
export const PresenceMonitor = () => {
  const {
    getBreadcrumbMenuProps,
    getPresenceMonitorDataProps,
    getPresenceMonitorTableProps,
    getPresenceMonitorCrudProps,
  } = usePresenceMonitorList();

  const { getPresenceMonitorStatsProps, getPresenceMonitorMetricsProps } = usePresenceMonitorDashboard();

  return (
    <>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />
      <Container>
        {/* Presence Monitor Dashboard */}
        <PresenceMonitorWidget styleName="gx-card-widget">
          <Row>
            <PresenceMonitorStats {...getPresenceMonitorStatsProps()} />
            <PresenceMonitorMetrics {...getPresenceMonitorMetricsProps()} />
            <PresenceMonitorBoard />
          </Row>
        </PresenceMonitorWidget>

        {/* Presence Monitor Table */}
        <Row>
          <Col span={24}>
            <Table
              {...getPresenceMonitorDataProps()}
              {...getPresenceMonitorTableProps()}
              {...getPresenceMonitorCrudProps()}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
