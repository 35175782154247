import React from 'react';
import { Modal } from 'antd';
import { useIntl } from 'react-intl';
import { IdcardOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/es/config-provider/SizeContext';

// Redux
import { useAppDispatch } from '../../hooks/App/useRedux';
import { manuallyAddTransaction } from '../../store/PresenceMonitor/PresenceMonitor.redux';

// Components
import { Translated } from '../UI/Core';
import { ColorButton } from '../UI/Button/ColorButton';

// Components
const { confirm } = Modal;

// Props
interface RemoveButtonProps {
  authorizedUserId: string;
  areaId: string;
  size?: SizeType;
  onOk?: () => void;
}

// Component
export const RemoveButton = ({ authorizedUserId, areaId, size = 'middle', onOk }: RemoveButtonProps) => {
  // Intl
  const intl = useIntl();
  // Redux
  const dispatch = useAppDispatch();

  // Callbacks
  const showRemovePersonConfirm = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    // Avoids clicking through
    e.stopPropagation();

    // Shows confirm modal
    confirm({
      icon: <IdcardOutlined />,
      title: intl.formatMessage({ id: 'presenceMonitor.form.confirm.removePerson' }),
      content: intl.formatMessage({ id: 'presenceMonitor.form.confirm.removePersonSub' }),
      okText: intl.formatMessage({ id: 'app.yes' }),
      cancelText: intl.formatMessage({ id: 'app.no' }),
      okType: 'primary',
      onOk: () => {
        dispatch(
          manuallyAddTransaction({
            AuthorizedUserId: authorizedUserId,
            AreaId: areaId,
          })
        );
        if (onOk) onOk();
      },
      onCancel: () => null,
    });
  };

  return (
    <ColorButton
      color="red"
      size={size}
      width={100}
      className="gx-mb-0 gx-ml-1"
      onClick={(e) => showRemovePersonConfirm(e)}
    >
      <span className="gx-ml-1" style={{ fontWeight: 600 }}>
        <Translated id="presenceMonitor.info.removePersonButton" />
      </span>
    </ColorButton>
  );
};
