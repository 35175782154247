import React, { useEffect } from 'react';
import { Space } from 'antd';
import { useIntl } from 'react-intl';

// Constants
import { AppColors } from '../../../constants/Utils/Colors';

// Components
import { Translated } from '../../UI/Core';
import { useData } from '../../../hooks/App/useData';
import { ApiEndpoints } from '../../../data/ApiEndpoints';
import { PersonType } from '../../../models/enums/PersonType';
import { DashboardProgress } from './DashboardProgress';
import { LineProgress } from '../../UI/Progress/LineProgress';
import { Flex } from '../../UI/Base';
import { DashboardIcon } from '../../UI/Icon/DashboardIcon';

// Component
export const PresenceMonitorBoard = () => {
  // Hooks
  const intl = useIntl();

  const { totalCount: VisitorCount, fetch: fetchVisitor } = useData(ApiEndpoints.PresenceMonitor.list, null);
  const { totalCount: employeeCount, fetch: fetchEmployee } = useData(ApiEndpoints.PresenceMonitor.list, null);

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetchVisitor({ personType: PersonType.Visitor });
    fetchEmployee({ personType: PersonType.Employee });
  }, [fetchEmployee, fetchVisitor]);

  const totalCount = VisitorCount + employeeCount;

  const summaryTooltip = (
    <Space direction="vertical">
      <Space>
        <DashboardIcon style={{ display: 'flex' }} />
        <span>
          <Translated id="presenceMonitor.dashboard.monitor.total" />{' '}
          <strong style={{ color: AppColors.cyan }}>{totalCount.toString()}</strong>
        </span>
      </Space>
    </Space>
  );

  // Render Functions
  const renderCharts = () => (
    <Space direction="vertical">
      <Space size={12}>
        {renderVisitors()}
        {renderEmployees()}
      </Space>
      <Flex alignItems="center">{renderTotal()}</Flex>
    </Space>
  );

  const renderVisitors = () => (
    <DashboardProgress
      label={intl.formatMessage({ id: 'presenceMonitor.dashboard.monitor.total.visitors' })}
      value={VisitorCount.toString()}
      percent={0}
      success={{ percent: 100, strokeColor: AppColors.lime }}
      strokeColor={AppColors.cyan}
    />
  );

  const renderEmployees = () => (
    <DashboardProgress
      label={intl.formatMessage({ id: 'presenceMonitor.dashboard.monitor.total.employees' })}
      value={employeeCount.toString()}
      percent={0}
      success={{ percent: 100, strokeColor: AppColors.cyan }}
      strokeColor={AppColors.lime}
    />
  );

  const renderTotal = () => (
    <LineProgress
      percent={100}
      success={{ percent: 100, strokeColor: AppColors.orange }}
      strokeColor={AppColors.orange}
      trailColor={AppColors.orange}
      tooltip={{ content: summaryTooltip, placement: 'bottom' }}
      empty={!totalCount}
    />
  );

  // Render
  return (
    <div className="ant-col gx-audi-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6">
      <div className="gx-site-dash gx-mb-2 gx-pt-3 gx-pt-sm-0 gx-pt-xl-2">
        <h6 className="gx-text-uppercase gx-mb-2 gx-mb-sm-4">
          <Translated id="presenceMonitor.dashboard.monitor.title" />
        </h6>
        <ul className="gx-line-indicator">{renderCharts()}</ul>
      </div>
    </div>
  );
};
