import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { CheckCircleFilled, DeleteOutlined, EditOutlined, InfoCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';

import { useParams } from 'react-router';
import { Visitor } from '../../models/Visits/Visitor';
import { ButtonSkeleton } from '../UI/Skeleton/ButtonSkeleton';
import { Translated } from '../UI/Core';
import { DetailHeader } from '../App/AppLayout/DetailHeader';
import { SignedNDA } from './SignedNDA';
import { useData } from '../../hooks/App/useData';
import { ApiEndpoints } from '../../data/ApiEndpoints';
import PacsExternalInfoModal from '../Modal/PacsExternalInfoModal';
import UploadImage from '../AuthorizedUserProfilePicture/UploadImage';

// Styled
const StyledSpan = styled.span`
  margin-left: 5px !important;
`;

interface ProfileHeaderProps {
  visitor: Visitor | null;
  pending: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showDeleteConfirm: (visitor: Visitor | null) => void;
}

export const ProfileHeader = ({ visitor, pending, setOpen, showDeleteConfirm }: ProfileHeaderProps) => {
  const [isSignedNdaOpen, setIsSignedNdaOpen] = useState(false);

  const signedDocumentUrl = visitor?.Signatures?.[0]?.FullDocument?.Url;
  const signatureUrl = visitor?.Signatures?.[0]?.Signature?.Url;
  const showSignedNDA = signedDocumentUrl || signatureUrl;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: dataUser, fetch: fetchUserMapping } = useData(ApiEndpoints.UserMapping.userMapping, null);

  const { id } = useParams();

  // Effects
  useEffect(() => {
    fetchUserMapping({ id });
  }, [fetchUserMapping, id]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <DetailHeader>
        <div style={{ marginTop: '-25px', marginBottom: '10px' }} className="gx-profile-banner-top">
          <div className="gx-profile-banner-top-left">
            <div className="gx-profile-banner-avatar">
              <UploadImage AuthorizedUserId={id} Url={visitor?.AuthorizedUserBlob?.Url} />
            </div>
            <div className="gx-profile-banner-avatar-info">
              <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">
                {pending ? <ButtonSkeleton width={240} height={16} /> : visitor?.FullName}
              </h2>
              <div className="gx-mb-0 gx-fs-lg">
                {pending ? <ButtonSkeleton width={160} height={16} /> : visitor?.CompanyName}
              </div>
            </div>
          </div>
          <div className="gx-profile-banner-top-right">
            {showSignedNDA && (
              <Button ghost type="primary" icon={<CheckCircleFilled />} onClick={() => setIsSignedNdaOpen(true)}>
                <StyledSpan>
                  <Translated id="visitor.signed.nda" />
                </StyledSpan>
              </Button>
            )}
            {dataUser && dataUser.length > 0 && (
              <Button ghost type="primary" icon={<InfoCircleFilled />} key="Info" onClick={() => showModal()} />
            )}
            <Button ghost type="primary" icon={<EditOutlined />} key="Edit" onClick={() => setOpen(true)}>
              <StyledSpan>
                <Translated id="app.edit" defaultMessage="Edit" />
              </StyledSpan>
            </Button>
            <Button ghost danger icon={<DeleteOutlined />} key="Delete" onClick={() => showDeleteConfirm(visitor)}>
              <StyledSpan>
                <Translated id="app.delete" defaultMessage="Delete" />
              </StyledSpan>
            </Button>
          </div>
        </div>
      </DetailHeader>
      <SignedNDA
        visitor={visitor}
        signedDocumentUrl={signedDocumentUrl}
        isModalOpen={isSignedNdaOpen}
        setIsModalOpen={setIsSignedNdaOpen}
      />
      <PacsExternalInfoModal isModalOpen={isModalOpen} onClose={handleClose} dataUser={dataUser} />
    </>
  );
};
