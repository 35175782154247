import React from 'react';
import { Space } from 'antd';
import { Translated } from '../../UI/Core';
import { ButtonSkeleton } from '../../UI/Skeleton/ButtonSkeleton';

export type VisitorStatsContent = {
  id: string;
  value: number;
  icon?: React.ReactElement;
  label?: React.ReactElement;
  color?: string;
};

interface VisitorStatsProps {
  content: Array<VisitorStatsContent> | undefined;
}

export const VisitorStats = ({ content }: VisitorStatsProps) => (
  <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-5">
    <div className="gx-wel-ema gx-pt-xl-2">
      <h1 className="gx-mb-3">
        <Translated id="visitors.dashboard.title" />
      </h1>
      <p className="gx-fs-sm gx-text-uppercase">
        <Translated id="visitors.dashboard.stats.title" />
      </p>
      <ul className="gx-list-group">
        {content?.map((item) => (
          <li key={item.id}>
            {item.icon}
            <span>
              <strong style={{ color: item.color }}>{item.value}</strong> {item.label}
            </span>
          </li>
        )) ?? (
          <Space direction="vertical">
            <ButtonSkeleton width={140} height={18} />
            <ButtonSkeleton width={110} height={18} />
            <ButtonSkeleton width={150} height={18} />
            <ButtonSkeleton width={130} height={18} />
          </Space>
        )}
      </ul>
    </div>
  </div>
);
