// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import { transactionManuallyAdded, transactionNotManuallyAdded } from './PresenceMonitor.redux';

// Events
const SignalREvents = {
  transactionManuallyAdded: `ManuallyAddedTransactionIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  transactionNotManuallyAdded: `ManuallyNotAddedTransactionIntegrationEvent`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Add Manual Transaction
  hubConnection.on(SignalREvents.transactionManuallyAdded, (msg) => {
    store.dispatch(transactionManuallyAdded({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.transactionNotManuallyAdded, (msg) => {
    store.dispatch(transactionNotManuallyAdded({ history, msg }));
  });
};
