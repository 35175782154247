import { redirectToApiAuthorizationPath } from '../../constants/Utils/Urls';
import { ApplicationPaths } from '../../constants/Settings/AuthSettings';
import { ApiGateway } from '../../constants/Settings/ApiSettings';
import { authService } from '../ApiAuthorization/AuthorizeService';

export const downloadReport = async (query: string) => {
  try {
    const token = await authService.getAccessToken();
    const response = await fetch(`${ApiGateway}${query}`, {
      method: 'GET',
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
    });

    if (response?.status === 403) {
      redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
    }

    if (!response.ok) return;

    const filename = response.headers.get('X-Filename') ?? '';

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error: any) {
    if (error?.response?.status === 403) {
      redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
    }
  }
};
