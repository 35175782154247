import React from 'react';

// Props
import { TableTitleProps } from '../../../../hooks/Table/useTableProps';

// Components
import { Flex, Text } from '../../Base';

// Render
export const TableTitle = ({
  title,
  DeleteButton,
  UploadButton,
  AddButton,
  ColumnsToggle,
  FilterSelect,
  DownloadReportButton,
}: TableTitleProps) => (
  <Flex justifyContent="space-between" alignItems="center">
    <Flex>
      <Text>{title}</Text>
    </Flex>
    <Flex>
      {FilterSelect && <FilterSelect />}
      {DeleteButton && <DeleteButton />}
      {UploadButton && <UploadButton />}
      {AddButton && <AddButton />}
      {ColumnsToggle && <ColumnsToggle />}
      {DownloadReportButton && <DownloadReportButton />}
    </Flex>
  </Flex>
);
