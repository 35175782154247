/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { SignalRAction } from '../../models/SignalRAction';
import { PersonType } from '../../models/enums/PersonType';
import { ManualTransaction } from '../../models/PresenceMonitor/ManualTransaction';

// State
interface PresenceMonitorState {
  updating: boolean;
  error: boolean;
  customTableFilters: CustomFilters;
}

const initialState: PresenceMonitorState = {
  updating: false,
  error: false,
  customTableFilters: { areaId: '', personType: PersonType.Default },
};

interface CustomFilters {
  areaId: string;
  personType: PersonType;
}

// Name
const storeName = '@PRESENCE_MONITOR';

// Redux Actions|Reducers
const presenceMonitorSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    manuallyAddTransaction: (state, _action: PayloadAction<ManualTransaction>) => {
      state.updating = true;
      state.error = false;
    },
    transactionManuallyAdded: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    transactionNotManuallyAdded: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Table custom filters
    updateCustomTableFilters: (state, action: PayloadAction<CustomFilters>) => {
      state.customTableFilters = action.payload;
    },
  },
});

// Export Actions
export const {
  // Delete
  manuallyAddTransaction,
  transactionManuallyAdded,
  transactionNotManuallyAdded,

  // Table custom filters
  updateCustomTableFilters,
} = presenceMonitorSlice.actions;

// Export Reducer
export default presenceMonitorSlice.reducer;
