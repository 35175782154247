// Generic
import GenericReducer from './Generic/Generic.redux';
// Account
import AccountReducer from './Account/Account.redux';
// AppSettings
import AppSettingsReducer from './AppSettings/AppSettings.redux';
// Employees
import EmployeesReducer from './Employees/Employees.redux';
import EmployeeIdentifiersReducer from './Employees/EmployeeIdentifiers/EmployeeIdentifiers.redux';
import EmployeeAccessProfilesReducer from './Employees/EmployeeAccessProfiles/EmployeeAccessProfiles.redux';
import EmployeeImportReducer from './Employees/EmployeeImport/EmployeeImport.redux';
// Visitors
import VisitorsReducer from './Visitors/Visitors.redux';
import VisitorIdentifiersReducer from './Visitors/VisitorIdentifiers/VisitorIdentifiers.redux';
import VisitorAccessProfilesReducer from './Visitors/VisitorAccessProfiles/VisitorAccessProfiles.redux';
// Visits
import VisitsReducer from './Visits/Visits.redux';
// Reservations
import ReservationsReducer from './Reservations/Reservations.redux';
import ReservationIdentifiersReducer from './Reservations/ReservationIdentifiers/ReservationIdentifiers.redux';
// Actions
import ActionsReducer from './Actions/Actions.redux';

// AccessProfileVerification
import AccessProfileVerificationReducer from './AccessProfileVerification/AccessProfileVerification.redux';

// PresenceMonitor
import PresenceMonitorReducer from './PresenceMonitor/PresenceMonitor.redux';

// AuthorizedUser
import AuthorizedUserProfilePictureSlice from './AuthorizedUserProfilePicture/AuthorizedUserProfilePicture.redux';

export const reducers = {
  // Generic
  generic: GenericReducer,
  // Account
  account: AccountReducer,
  // AppSettings
  appSettings: AppSettingsReducer,
  // Employees
  employees: EmployeesReducer,
  employeeIdentifiers: EmployeeIdentifiersReducer,
  employeeAccessProfiles: EmployeeAccessProfilesReducer,
  employeeImport: EmployeeImportReducer,
  // Visitors
  visitors: VisitorsReducer,
  visitorsIdentifiers: VisitorIdentifiersReducer,
  visitorsAccessProfiles: VisitorAccessProfilesReducer,
  // Visits
  visits: VisitsReducer,
  // Reservations
  reservations: ReservationsReducer,
  reservationsIdentifiers: ReservationIdentifiersReducer,
  // Actions
  actions: ActionsReducer,
  // AccessProfileVerification
  accessProfileVerification: AccessProfileVerificationReducer,
  // PresenceMonitor
  presenceMonitor: PresenceMonitorReducer,
  // AuthorizedUser
  authorizedUserProfilePicture: AuthorizedUserProfilePictureSlice,
};
